import React, { useEffect } from 'react';

declare global {
    interface Window {
        kWidget: any; // Kaltura widget library
    }
}

interface KalturaPlayerProps {
    partnerId: string;
    uiConfId: string;
    entryId: string;
    pluginOptions?: Record<string, any>; // Additional plugin configurations
}

const KalturaPlayer: React.FC<KalturaPlayerProps> = ({ partnerId, uiConfId, entryId, pluginOptions }) => {
    useEffect(() => {
        const loadKalturaScript = () => {
            if (document.getElementById('kaltura-script')) {
                console.log('Kaltura script already loaded.');
                initializePlayer();
                return;
            }

            const script = document.createElement('script');
            script.id = 'kaltura-script';
            script.src = `https://cdnapisec.kaltura.com/p/${partnerId}/sp/${partnerId}00/embedIframeJs/uiconf_id/${uiConfId}`;
            script.async = true;

            document.body.appendChild(script);

            script.onload = () => {
                console.log('Kaltura script loaded.');
                initializePlayer();
            };

            script.onerror = () => {
                console.error('Failed to load Kaltura script.');
            };
        };

        const initializePlayer = () => {
            if (!window.kWidget) {
                console.error('kWidget is not defined.');
                return;
            }

            // Use kWidget to embed the player
            window.kWidget.embed({
                targetId: 'kaltura-player-container',
                wid: `_${partnerId}`, // Partner ID prefixed with "_"
                uiconf_id: uiConfId,
                entry_id: entryId,
                flashvars: {
                    ks: 'djJ8NjEyNjk0MnwbGnq4Thk5L8DAZ9EaMJNTiDbTT82YN3DOd3_sd79NbRpFS3kAamYJkuK14F16uJT69DDvxlstfd5VMQW2o2l1ZebI-EgfuCnfXMTeF5dDYavlZsDWsj_5TAv6o34zhmoxLrRYE_tgjlfnmSRNuVVW',
                    plugins: {
                        myCustomPlugin: pluginOptions || {}, // Pass custom plugin configurations
                    },
                },
                params: {
                    autoplay: true,
                },
                readyCallback: (playerInstance: any) => {
                    console.log('Player is ready:', playerInstance);

                    // Add custom functionality here
                    playerInstance.addEventListener('play', () => {
                        console.log('Video started playing.');
                    });
                },
            });
        };

        loadKalturaScript();
    }, [partnerId, uiConfId, entryId, pluginOptions]);

    return <div id="kaltura-player-container" style={{ width: '100%', height: '500px' }} />;
};

export default KalturaPlayer;
