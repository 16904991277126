import React from 'react';
import { useParams } from 'react-router-dom';
import KalturaPlayer from '../components/KalturaPalyer/KalturaPlayer';

const KalturaPage: React.FC = () => {
    const { entryId } = useParams<{ entryId: string }>();

    return (
        <div>
            <h1>Kaltura Player</h1>
            <KalturaPlayer
                partnerId="6126942" // Replace with your Kaltura Partner ID
                uiConfId="55999342"   // Replace with your Kaltura UI Configuration ID
                entryId='1_hom0ltgu'//{entryId || 'DEFAULT_ENTRY_ID'} // Use dynamic entryId or a default fallback
                pluginOptions={{ text: 'My Custom Button' }} // Customize your plugin options
            />
            <iframe
                src="https://www.kaltura.com/index.php/extwidget/preview/partner_id/6126942/uiconf_id/55985732/entry_id/1_hom0ltgu/embed/dynamic"
                width="640"
                height="360"
            >
            </iframe>
        </div>
    );
};

export default KalturaPage;
